import React from 'react'
import styled from 'styled-components'
// import { ReUsePtag } from './ReUsePtag'
// import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHOneTag } from './ReUseHOneTag'
// import { DemoRequestButton } from './DemoRequestButton'
import { LinkHubspot } from './LinkHubspot'
import { Button } from './Button'

const AppLearnMoreContactUSSectionWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  height: auto;
  width: 95vw;
  margin-top: 70px;
  margin-bottom: 70px;

  .LearnMoreContactUSSection {
    height: 20vw;
    display: flex;
    justify-content: center; /* Centers the content horizontally */
    align-items: center; /* Centers the content vertically */
    flex-direction: column; /* Stack content for better responsiveness */
    text-align: center;
    margin: 0 auto; /* Centers the section horizontally */
    padding: 50px 20px; /* Add padding for mobile */
    background-color: #77aa77;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2377aa77'/%3E%3Cstop offset='1' stop-color='%234fd'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    background-position: center; /* Ensure the background is centered */
    border-radius: 42px;
    @media (max-width: 1090px) {
      height: 30vw;
    }
    @media (max-width: 768px) {
      height: 55vw;
      margin: 0;
      padding: 0;
    }
  }

  .LearnMoreContactUSHeading {
    width: auto;
    text-align: center;
    margin-top: 55px;
    font-size: 2.5rem;
    color: #fff;
    @media (max-width: 1650px) {
      margin-top: 55px;
    }
    @media (max-width: 1090px) {
      padding-top: 35px;
    }
    @media (max-width: 480px) {
      font-size: 42px;
    }
    @media (max-width: 1090px) {
      font-size: 1.5rem;
    }
  }

  .LearnMoreContactUSBannerPara {
    width: 100vw;
    text-align: center;
    color: #5f5f5f;
    font-size: 1rem;
    @media (min-width: 1650px) {
      font-size: 1.1rem;
    }
    @media (max-width: 1090px) {
      font-size: 0.8rem;
      padding: 8px 12px 8px 12px;
    }
    @media (max-width: 425px) {
      display: none;
    }
  }

  .HomeBannerImageSection {
    width: 50%;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }

  .LearnMoreContactUSContentSection {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers items horizontally */
    justify-content: center; /* Centers items vertically */
    width: 100%;
    max-width: 1200px; /* Limit the width of content */
    margin: 0 auto; /* Centers content within the parent */
    text-align: center;
  }

  .LearnMoreContactUSButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;
    flex-wrap: wrap;
  }
  .LearnMoreContactUSHeading,
  .LearnMoreContactUSBannerPara {
    margin: 0 auto;
    max-width: 1920px; /* Restrict text width for readability */
    padding-bottom: 10px;
  }
`

export const AppLearnMoreContactUSSection = () => {
  return (
    <AppLearnMoreContactUSSectionWapper>
      <div className="LearnMoreContactUSSection">
        <div className="LearnMoreContactUSContentSection">
          <ReUseHOneTag
            Heading="Learn more about Raindrop Contract Management"
            HeadingStyle="LearnMoreContactUSHeading"
          />
          {/* <ReUsePtag
            para="To learn more about Raindrop Contract Management feel free to reach out to us."
            paraStyle="LearnMoreContactUSBannerPara "
          /> */}
          <div className="LearnMoreContactUSButton">
            <LinkHubspot to="https://share.hsforms.com/15ZhMdunrTji6Z5_wWjrDyA4702y">
              <Button cta="Get Started" label="Get Started" type="submit" className="demobutton" />
            </LinkHubspot>
            {/* <DemoRequestButton label="Talk To Us" /> */}
          </div>
        </div>
        {/* <div className="HomeBannerImageSection">
          <ReuseImgTag
            ImageStyle="LearnMoreContactUSImage"
            ImagePath="/img/partners-banner.png"
            AltName="Company-partners-Banner-image"
          />
        </div> */}
      </div>
    </AppLearnMoreContactUSSectionWapper>
  )
}
