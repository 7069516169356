import React from 'react'
import styled from 'styled-components'

const SectionWrapper = styled.div`
  max-width: 1920px;
  margin: auto;
  width: 96vw;
  display: flex;
  flex-direction: column; /* Stack badges and content vertically */
  padding: 50px 20px;

  .badges-and-image-container {
    display: grid;
    grid-template-columns: 1fr auto; /* Left side (badges-section) and right side (image-container) */
    align-items: flex-start; /* Align items at the top */
    margin-bottom: 40px; /* Space between badges/image and content */

    @media (max-width: 1024px) {
      grid-template-columns: 1fr; /* Stack columns vertically */
      justify-items: center; /* Center items horizontally */
    }
  }

  .badges-section {
    display: flex;
    justify-content: flex-start; /* Align badges to the left */
    align-items: flex-start;
    gap: 60px; /* Space between badges */
    @media (max-width: 425px) {
      gap: 10px;
    }

    img {
      max-width: 150px; /* Adjust badge size */
      height: auto;
      border: none;
      @media (max-width: 768px) {
        max-width: 100px;
      }
    }
    @media (max-width: 1024px) {
      justify-content: center; /* Center badges horizontally */
      align-items: center;
    }
  }

  .image-container {
    text-align: right; /* Align image to the right */

    img {
      max-width: 100%;
      height: auto;
      border: none;
    }

    @media (max-width: 768px) {
      text-align: center; /* Center image horizontally */
    }
  }

  .content-section {
    grid-column: 1 / 2; /* Place content-section in the first column (left side) */
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Space between description and image */

    .description {
      flex: 1;
      min-width: 300px;
      color: #1f1f1f;
      font-size: 1rem;
      line-height: 1.5;

      p {
        @media (min-width: 1650px) {
          font-size: 1.3rem;
        }
      }

      @media (max-width: 1090px) {
        font-size: 0.9rem;
        padding-top: 8px;
      }
    }
  }

  .thank-you-section {
    height: 60vh;
    flex: 1;
    min-width: 300px;
    max-width: 450px;
    background: #fff;
    padding: 20px;
    margin-top: 12px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      color: #1f1f1f;
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    p {
      color: #1f1f1f;
      font-size: 1rem;
      margin-bottom: 20px;
    }

    button {
      background-color: #34a1d5;
      color: white;
      border: none;
      border-radius: 42px;
      padding: 10px 20px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #102c64;
      }
    }

    .support-text {
      margin-top: 60px;
      font-size: 0.8rem;
      color: #888;
    }
  }
  .HackettCLMQuote-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 93vw;
    /* background-color: #77aa77;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2377aa77'/%3E%3Cstop offset='1' stop-color='%234fd'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E"); */
    background-color: #000000;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg %3E%3Ccircle fill='%23000000' cx='400' cy='400' r='600'/%3E%3Ccircle fill='%23230046' cx='400' cy='400' r='500'/%3E%3Ccircle fill='%232f0052' cx='400' cy='400' r='400'/%3E%3Ccircle fill='%233b075e' cx='400' cy='400' r='300'/%3E%3Ccircle fill='%2348156a' cx='400' cy='400' r='200'/%3E%3Ccircle fill='%23552277' cx='400' cy='400' r='100'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    background-position: center; /* Ensure the background is centered */
    border-radius: 42px;
    padding: 80px 40px 80px 40px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    overflow: hidden; /* Prevent any overflow issues */
    @media (min-width: 425px) {
      margin-top: 55px;
    }
    @media (max-width: 425px) {
      width: 88vw;
    }

    p {
      text-align: center;
      color: #fff;
      line-height: 34px;
    }
  }
`

export const AppHackettCLMBadgesSection = () => {
  return (
    <SectionWrapper>
      {/* Badges and Image Container */}
      <div className="badges-and-image-container">
        <div>
          {/* Badges Section */}
          <div className="badges-section">
            <img
              src="https://storage.googleapis.com/raindroppublic/website_data/Hackett-CLM-Implementation-Metrics-Badge.png"
              alt="Hackett-CLM-Contract-Creation-and-Authoring-Badge"
            />
            <img
              src="https://storage.googleapis.com/raindroppublic/website_data/Hackett-CLM-User-Adoption-and-Experience-Badge.png"
              alt="Hackett-CLM-Implementation-Metrics-Badge"
            />
            <img
              src="https://storage.googleapis.com/raindroppublic/website_data/Hackett-CLM-Contract-Creation-and-Authoring-Badge.png"
              alt="Hackett-CLM-User-Adoption-and-Experience-Badge"
            />
          </div>

          {/* Content Section */}
          <div className="content-section">
            <div className="description">
              <p>
                Raindrop is pleased to be included in The Hackett Group’s research on 21 Contract Lifecycle Management
                (CLM) and intelligent solution providers. CLM solutions are a core component of upstream procurement
                tools within the source-to-pay ecosystem.
              </p>
            </div>
          </div>
        </div>

        {/* Image Container */}
        <div className="image-container">
          <img
            src="https://storage.googleapis.com/raindroppublic/website_data/2025-CLM-Study-Results-RaindropCarve-Out-Content.jpg"
            alt="Gartner Hype Cycle for Procurement and Sourcing Solutions 2024"
          />
        </div>
      </div>
      <div className="image-container">
        <img
          src="https://storage.googleapis.com/raindroppublic/website_data/2025-CLM-Study-Results-RaindropCarve-Out-Content1.jpg"
          alt="Gartner Hype Cycle for Procurement and Sourcing Solutions 2024"
        />
      </div>
      <div className="HackettCLMQuote-section">
        <p>
          “Raindrop was designed by procurement professionals for procurement with a source-to-pay platform that
          includes contract lifecycle management, intake orchestration and more. The platform is impressive with it’s
          focus on functionality that matters, usability, and ease of adoption.”
        </p>
      </div>
    </SectionWrapper>
  )
}
