import React from 'react'
import { Layout } from '../../components/AppLayout'
import { RDHelmet } from '../../components/RDHelmet'
import { AppHackettCLMBanner } from '../../components/AppHackettCLMBanner'
import { AppHackettCLMBadgesSection } from '../../components/AppHackettCLMBadgesSection'
import { AppHackettCLMKeyfindings } from '../../components/AppHackettCLMKeyfindings'
import { AppLearnMoreContactUSSection } from '../../components/AppLearnMoreContactUSSection'

const HackettCLM = () => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway Suite 250',
      addressRegion: ' San Jose, California 95110',
      postalCode: '95110',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  return (
    <Layout>
      <RDHelmet
        subtitle={'HackettCLM'}
        contentDescription="Discover Raindrop's inclusion in The Hackett Group’s 2024 Contract Lifecycle Management (CLM) and Intelligent Software Provider Perspective. Learn how Raindrop’s AI-powered CLM solution enhances contract automation, reduces cycle times, and drives procurement efficiency."
        contentKeywords="content='Hackett CLM report 2024, Contract Lifecycle Management software, AI-powered CLM solutions, Hackett Group CLM matrix, Raindrop CLM platform, procurement efficiency tools, AI-driven contract automation, spend analytics software, generative AI in procurement, contract management software'"
        contentOG="AI Powered Source-to-Pay SaaS Platform"
        contentOgDescription="Discover Raindrop's inclusion in The Hackett Group’s 2024 Contract Lifecycle Management (CLM) and Intelligent Software Provider Perspective. Learn how Raindrop’s AI-powered CLM solution enhances contract automation, reduces cycle times, and drives procurement efficiency."
        contentOgUrl="https://raindrop.com/resources/HackettCLM"
        schemaMarkup={schemaMarkup}
      />
      <AppHackettCLMBanner />
      <AppHackettCLMBadgesSection />
      <AppHackettCLMKeyfindings />
      <AppLearnMoreContactUSSection />
    </Layout>
  )
}

export default HackettCLM
