import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    Title:
      'Key findings and insights from The Hackett Group® CLM Digital World Class® Matrix Report 2024 include the following.',
    paraOne: 'Key solution features and improvement opportunities include:',
    icon: <FaArrowCircleRight />,
    paraPonitOne:
      'Full visibility to spend with user-defined or persona-based dashboards, robust reporting and visualizations, AI-powered decision support, and end-to-end analytics.',
    paraPonitTwo:
      'Varying levels of success using AI for conversational search, creation of contracts (improving the output and receptivity from employees), and generating AI-driven contract reviews.',
    paraPonitThree:
      'Spend analytics, contract lifecycle management, and category management are the biggest opportunity areas for using generative AI in procurement',
    paraTwo: 'The impact of a CLM solution:',
    paraPonitFour: '63% increase in contract automation and efficiency',
    paraPonitFive: '35% Reduction in average cycle time to complete a contract',
    paraPonitSix: '81% Adoption of contracts within the CLM solution',
    paraThree:
      'AI capabilities are extensive for contract intake, search, data management, creation, authoring and workflows.',
  },
]
