import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import HackettCLMKeyfindingsData from '../Data/HackettCLMKeyfindingsData'

const HackettCLMKeyfindingsWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  padding: 0% 8%;
  .HackettCLMKeyfindingsHeading {
    padding: 2% 0% 2% 0%;
    font-size: 2rem;
  }
  .HackettCLMKeyfindingsiconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .ParaSize {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: bolder;
  }
  .ParaSize-para {
    font-size: 1.1rem;
    line-height: 1.4;
  }
`

export const AppHackettCLMKeyfindings = () => {
  return (
    <HackettCLMKeyfindingsWapper>
      {HackettCLMKeyfindingsData.map((data, index) => {
        return (
          <div key={index}>
            <ReUseHTwotag Heading={data.Title} HeadingStyle="HackettCLMKeyfindingsHeading" />
            <ReUsePtag para={data.paraOne} paraStyle="ParaSize" />
            <ReUsePtag
              para={data.paraPonitOne}
              Icon={data.icon}
              IconStyle="HackettCLMKeyfindingsiconColor"
              paraStyle="ParaSize-para"
            />
            <ReUsePtag
              para={data.paraPonitTwo}
              Icon={data.icon}
              IconStyle="HackettCLMKeyfindingsiconColor"
              paraStyle="ParaSize-para"
            />
            <ReUsePtag
              para={data.paraPonitThree}
              Icon={data.icon}
              IconStyle="HackettCLMKeyfindingsiconColor"
              paraStyle="ParaSize-para"
            />
            <ReUsePtag para={data.paraTwo} paraStyle="ParaSize" />
            <ReUsePtag
              para={data.paraPonitFour}
              Icon={data.icon}
              IconStyle="HackettCLMKeyfindingsiconColor"
              paraStyle="ParaSize-para"
            />
            <ReUsePtag
              para={data.paraPonitFive}
              Icon={data.icon}
              IconStyle="HackettCLMKeyfindingsiconColor"
              paraStyle="ParaSize-para"
            />
            <ReUsePtag
              para={data.paraPonitSix}
              Icon={data.icon}
              IconStyle="HackettCLMKeyfindingsiconColor"
              paraStyle="ParaSize-para"
            />
            <ReUsePtag para={data.paraThree} paraStyle="ParaSize" />
          </div>
        )
      })}
    </HackettCLMKeyfindingsWapper>
  )
}
